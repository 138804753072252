import React from 'react';
import { Heading, Text } from '../components/typography';
import { Layout } from '../components/layout';
import './404.scss';

const NotFoundPage = () => (
    <Layout seoData={{ title: 'Page Not Found' }} className="page-not-found">
        <Heading level="1" size="large" className="page-not-found--title">
            Page Not Found
        </Heading>
        <Text size="subtitle">This page isn't real. Nothing is real.</Text>
    </Layout>
);

export default NotFoundPage;
